import Flickity from 'Flickity'

function DOMready(callback) {
  document.readyState !== 'loading'
    ? callback()
    : document.addEventListener('DOMContentLoaded', callback)
}

DOMready(function () {
  const carousel = document.querySelector('.video-cards__carousel')
  const lazyVideos = [...document.querySelectorAll('video.lazy')]

  if (carousel !== null) {
    // eslint-disable-next-line no-unused-vars
    const flkty = new Flickity(carousel, {
      // options
      cellAlign: 'left',
      prevNextButtons: false,
      pageDots: false,
      contain: true,
      // wrapAround: true,
      // percentPosition: false,
      // contain: true,
    })

    // eslint-disable-next-line no-undef
    class videoCard extends HTMLElement {
      constructor() {
        super()
        const video = this.querySelector('video')
        if (!video) return
        const playButton = this.querySelector('.video-card__btn-play')
        const muteButton = this.querySelector('.video-card__btn-mute')

        playButton.addEventListener('click', this.playContent.bind(video))
        muteButton.addEventListener('click', this.muteContent.bind(video))
      }

      playContent() {
        const btnsPlay = [...document.querySelectorAll('.video-card__btn-play')]
        const isPlaying =
          this.currentTime > 0 &&
          !this.paused &&
          !this.ended &&
          this.readyState > this.HAVE_CURRENT_DATA

        btnsPlay.forEach((btn) => {
          btn.classList.remove('video-card__btn-play--playing')
        })

        if (!isPlaying) {
          window.pauseAllMedia()
          const playVideo = this.play()
          if (playVideo !== undefined) {
            playVideo.then((_) => {}).catch((error) => {})
          }

          this.parentElement
            .querySelector('.video-card__btn-play')
            .classList.add('video-card__btn-play--playing')
        } else {
          window.pauseAllMedia()
          this.parentElement
            .querySelector('.video-card__btn-play')
            .classList.remove('video-card__btn-play--playing')

          this.pause()
        }
      }

      muteContent() {
        const btnsPlay = [...document.querySelectorAll('.video-card__btn-play')]

        if (this.muted === false) {
          this.muted = true
          this.parentElement
            .querySelector('.video-card__btn-mute')
            .classList.add('video-card__btn-mute--muted')
        } else {
          this.muted = false
          this.parentElement
            .querySelector('.video-card__btn-mute')
            .classList.remove('video-card__btn-mute--muted')

          btnsPlay.forEach((btn) => {
            btn.classList.remove('video-card__btn-play--playing')
          })

          window.pauseAllMedia()

          btnsPlay.forEach((btn) => {
            btn.classList.remove('video-card__btn-play--playing')
          })

          this.play()

          this.parentElement
            .querySelector('.video-card__btn-play')
            .classList.add('video-card__btn-play--playing')
        }
      }
    }

    // eslint-disable-next-line no-undef
    customElements.define('video-card', videoCard)

    if ('IntersectionObserver' in window) {
      const lazyVideoObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (const source in video.target.children) {
              const videoSource = video.target.children[source]
              if (
                typeof videoSource.tagName === 'string' &&
                videoSource.tagName === 'SOURCE'
              ) {
                videoSource.src = videoSource.dataset.src
                console.log(videoSource.src)
              }
            }

            video.target.load()
            video.target.classList.remove('lazy')
            video.target.parentElement.querySelector('img').style.zIndex = '0'
            video.target.parentElement.querySelector('img').style.opacity = '0'

            lazyVideoObserver.unobserve(video.target)
          }
        })
      })

      lazyVideos.forEach(function (lazyVideo) {
        lazyVideoObserver.observe(lazyVideo)
      })
    }
  }
})
